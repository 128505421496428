@import './styles/fonts.css';

* {
   padding: 0;
   margin: 0;
   box-sizing: border-box;
   font-family: "Inter";
   font-display: fallback;
}

.helper-text {
   padding-top: 4px;
   width: 100%;
   color: #FF5454 !important;
   text-align: left;
   font-weight: 600;
}

.helper-text-comment {
   padding-top: 4px;
   width: 100%;
   color: #666666 !important;
   text-align: center !important;
   font-weight: 600;
}

@-webkit-keyframes rotating

/* Safari and Chrome */
   {
   from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
   }

   to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
   }
}

@keyframes rotating {
   from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
   }

   to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
   }
}

.rotating {
   -webkit-animation: rotating 1s linear infinite;
   -moz-animation: rotating 1s linear infinite;
   -ms-animation: rotating 1s linear infinite;
   -o-animation: rotating 1s linear infinite;
   animation: rotating 1s linear infinite;
}

.plus-opacity-0 svg {
   background: red;
}

#root {
   overflow-x: hidden;
}
html, body {
   background-color: #f7f7f7;
}